import { cn } from '@nextui-org/react';
import { CountryCode } from 'libphonenumber-js';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInputX from 'react-phone-input-2';

interface Props {
  phone: string;
  phoneCountry: string;
  setPhone: (phone: string) => void;
  setPhoneCountry: (phoneCountry: CountryCode) => void;
  placeholder?: string;
  label?: string;
  onEnter?: () => void;
  isInvalid?: boolean;
  errorMessage?: string;
  disabled?: boolean;
}

const PhoneInput = ({
  phone,
  phoneCountry,
  setPhone,
  setPhoneCountry,
  placeholder = 'Enter mobile number',
  label = 'Enter mobile number',
  onEnter,
  isInvalid = false,
  errorMessage,
  disabled = false,
}: Props) => {
  const { t } = useTranslation();
  const phoneRef = useRef<any>();

  useEffect(() => {
    if (!phoneRef.current || !phoneRef.current?.state.selectedCountry.iso2)
      return;
    const pc = phoneRef.current?.state.selectedCountry.iso2.toUpperCase();
    setPhoneCountry(pc);
  }, [phone, setPhoneCountry]);

  return (
    <div className="space-y-1">
      <label
        className={cn(
          'text-start text-foreground text-sm font-display',
          isInvalid && 'text-danger',
        )}
        htmlFor="phoneNumber"
      >
        {t(label)}
      </label>
      <PhoneInputX
        // @ts-ignore
        ref={phoneRef as any}
        country={phoneCountry ?? 'jo'}
        excludeCountries={['il']}
        placeholder={`${t(placeholder)}`}
        containerClass={cn('w-full force-ltr !z-20')}
        inputClass={cn(
          '!rounded-xl shadow-md !border-2 focus:!ring-0 !pt-2 !pb-1.5 min-w-full dark:!bg-jacarta-700 dark:!text-white',
          isInvalid
            ? '!border-danger text-danger'
            : ' focus:!border-black hover:!border-black/50 dark:!border-jacarta-500',
        )}
        dropdownClass="!fixed dark:!bg-jacarta-700 [&>li:hover]:dark:!bg-jacarta-600 [&>li]:transition-colors"
        inputProps={{ id: 'phoneNumber' }}
        value={phone}
        onChange={setPhone}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onEnter?.();
        }}
        disabled={disabled}
      />
      {errorMessage && isInvalid && (
        <div className="text-danger text-xs">{errorMessage}</div>
      )}
    </div>
  );
};
export default PhoneInput;
