import useBreakpoint from '@/hooks/useBreakpoint';
import { primaryButton } from '@/theme/nextUI';
import { Button } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { FaGift } from 'react-icons/fa6';
// import { LuClock } from 'react-icons/lu';
// import LiveCountdown from '../dashboard/live/liveCountdown';
import useLeaderboardSeason from '@/hooks/useLeaderboardSeason';
import dayjs from 'dayjs';

const SeasonPrize = () => {
  const { t } = useTranslation();
  const { isLess: isLessMd } = useBreakpoint('md');
  const { season, seasonEnd, initialSeason } = useLeaderboardSeason();

  const now = dayjs().utc();
  const finishedSeason = seasonEnd.diff(now) < 0;

  return (
    <div className="flex flex-col justify-center items-center gap-2 md:gap-3">
      {/* <div className=" flex items-center justify-between gap-2">
        <span className="font-semibold">
          {t(season == 0 ? 'Starts in' : 'Ends in')}
        </span>
        <div className="rounded-full border-2 border-accent px-2 py-1 font-display flex items-center justify-center gap-1">
          <LuClock size={20} className="shrink-0" />
          <LiveCountdown
            time={season == 0 ? initialSeason.toDate() : seasonEnd.toDate()}
            disableSeconds
            completionText="Already Finished"
          />
        </div>
      </div> */}

      <Button
        className={primaryButton}
        radius="full"
        startContent={<FaGift size={18} className="hidden sm:inline-block" />}
        size={isLessMd ? 'sm' : 'md'}
        isDisabled={season == 0 || !finishedSeason}
      >
        {season == 0 ? t('Prizes Coming Soon') : t('Claim Prize')}
      </Button>
    </div>
  );
};

export default SeasonPrize;
