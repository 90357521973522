import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  Select,
  SelectItem,
} from '@nextui-org/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import cookies from 'js-cookie';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Flag from 'react-world-flags';
import useCountries from '../../hooks/useCountries';
import { useYup } from '../../hooks/useYup';
import {
  CORSet,
  setCountryOfResidenceModalHide,
} from '../../redux/counterSlice';
import { modalStyle } from '../../theme/nextUI';
import nodeAPI from './../../lib/nodeapi';

const SetCountryOfResidenceModal = () => {
  const { setCountryOfResidenceModal } = useSelector((state) => state.counter);
  const router = useRouter();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data: session, status } = useSession();
  const { countries } = useCountries();

  const { data: myCountry } = useQuery({
    queryKey: ['My Country'],
    queryFn: async () => {
      const { countryId, whichCountry } = await nodeAPI(
        'GET',
        session.secure,
        'auth/hasCountryAndDate',
        {},
      );

      return { countryId, whichCountry };
    },
    enabled: status == 'authenticated',
  });

  const { Yup } = useYup();

  const schema = Yup.object({
    country_id: Yup.number().required('Country is required'),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  const countryId = watch('country_id') ?? myCountry?.countryId;
  const countryCode = useMemo(
    () => countries?.find(({ id }) => id == countryId)?.country_code,
    [countryId, countries],
  );

  const { mutate: submit, isPending } = useMutation({
    mutationFn: async () => {
      if (status != 'authenticated') return;

      await nodeAPI('POST', session.secure, 'profile/setUserCountry', {
        country_id: countryId,
      });

      dispatch(CORSet());
      cookies.set('geolocation', countryCode, { expires: 1 / 24 });

      closeModal();
    },
  });

  const closeModal = () => {
    dispatch(setCountryOfResidenceModalHide());
    reset();
  };

  return (
    <div>
      <Modal
        isOpen={setCountryOfResidenceModal}
        onClose={closeModal}
        classNames={modalStyle}
      >
        <ModalContent as="form" onSubmit={handleSubmit(submit)}>
          <ModalBody>
            <div className="flex flex-col items-center gap-3">
              <p className="text-md font-display text-center mt-5 text-lg">
                {t('Please select your country of residence')}
              </p>
              <p className="text-center text-sm text-red">
                {t('Warning: You cannot change your country after selection')}
              </p>

              <Select
                isDisabled={myCountry?.countryId}
                {...register('country_id')}
                errorMessage={errors?.country_id?.message}
                placeholder={t('Select Country')}
                onSelectionChange={(e) => {
                  setValue('country_id', e.currentKey);
                }}
                disallowEmptySelection
                defaultSelectedKeys={[myCountry?.countryId + '']}
                size="md"
                labelPlacement="outside"
                variant="bordered"
                aria-label="country-switcher"
                isRequired
                startContent={
                  <Flag
                    code={countryCode}
                    fallback={<span> </span>}
                    style={{
                      width: '32px',
                      height: '32px',
                    }}
                  />
                }
              >
                {countries?.map((element) => (
                  <SelectItem
                    key={element.id}
                    value={element.id}
                    aria-label={element.country_enName ?? 'country-field'}
                  >
                    {router.locale === 'en-US'
                      ? element.country_enName
                      : element.country_arName}
                  </SelectItem>
                ))}
              </Select>
            </div>
          </ModalBody>
          <ModalFooter className="flex-col items-center">
            <Button
              type="submit"
              className={`bg-accent rounded-full capitalize text-white font-semibold ${myCountry?.countryId ? 'cursor-not-allowed bg-jacarta-300' : ''}`}
              isLoading={isPending}
              isDisabled={myCountry?.countryId}
            >
              {t('Set My Region')}
            </Button>

            <p className="text-sm font-display mt-2 text-jacarta-600 dark:text-jacarta-300">
              {t(
                'Taxes and regulations may differ from one region to another.',
              )}
            </p>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default SetCountryOfResidenceModal;
