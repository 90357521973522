import { useAppSelector } from '@/redux/counterSlice';
import { LiveTournament } from '@/types/types';
import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import nodeAPI from '@/lib/nodeapi';

const useLiveTournaments = () => {
  const { data: session, status } = useSession();
  const { liveKey } = useAppSelector((state) => state.counter);

  const tournaments = useQuery<LiveTournament[] | undefined>({
    queryKey: ['Live Tournaments', session?.secure, liveKey],
    queryFn: async () => {
      const { data: live } = await nodeAPI(
        'GET',
        session?.secure,
        'play/getLiveTournaments',
      );

      return live;
    },
    enabled: status == 'authenticated',
    placeholderData: [],
  });

  return tournaments;
};

export default useLiveTournaments;
