import { Template, TournamentType } from '@/types/types';
import { cn } from '@nextui-org/react';
import { useRouter } from 'next/router';
import { FaGamepad, FaStopwatch } from 'react-icons/fa6';
import { HiUsers } from 'react-icons/hi';
import { MdGamepad } from 'react-icons/md';

const TournamentPills = ({
  template,
  durationTextEN,
  durationTextAR,
}: {
  template: Template;
  hidePrize?: boolean;
  tournamentType?: TournamentType;
  durationTextEN: string;
  durationTextAR: string;
}) => {
  const router = useRouter();

  const getPlayersText = (count: number) => {
    if (router.locale === 'en-US') return `${count} ${'Players'}`;

    if (count <= 10) return `${count} ${'لاعبين'}`;
    return `${count} ${'لاعب'}`;
  };

  const PILLS = {
    GAME_MODE: {
      icon: <MdGamepad size={20} />,
      value:
        router.locale === 'en-US'
          ? template.game_mode_details.game_mode_en
          : template.game_mode_details.game_mode_ar,
    },
    DURATION: {
      icon: <FaStopwatch size={20} />,
      value: router.locale === 'en-US' ? durationTextEN : durationTextAR,
    },
    MAX_PER_BRACKET: {
      icon: <HiUsers size={20} />,
      value: getPlayersText(
        template.max_per_bracket *
          (template.league ? template.max_brackets : 1),
      ),
    },
    FIRST_GAMES: {
      icon: <FaGamepad size={20} />,
      value:
        router.locale === 'en-US'
          ? template.firstGamesEN
          : template.firstGamesAR,
    },
  };

  if (template.league)
    return (
      <div className="grid grid-cols-2 items-center justify-center gap-1.5 text-center flex-wrap">
        <Pill
          pill={PILLS.FIRST_GAMES}
          className="col-span-2"
          showEndIcon
          textClassName="text-center"
        />
        <Pill pill={PILLS.MAX_PER_BRACKET} />
        <Pill pill={PILLS.DURATION} />
      </div>
    );

  return (
    <div className="grid grid-cols-2 items-center justify-center gap-1.5 text-center flex-wrap">
      <Pill
        pill={PILLS.GAME_MODE}
        className="col-span-2"
        showEndIcon
        textClassName="text-center"
      />
      <Pill pill={PILLS.MAX_PER_BRACKET} />
      <Pill pill={PILLS.FIRST_GAMES} />
    </div>
  );
};

const Pill = ({
  pill: { value, icon },
  className,
  showEndIcon = false,
  textClassName,
}: {
  pill: { value: string | number; icon: JSX.Element };
  className?: string;
  showEndIcon?: boolean;
  textClassName?: string;
}) => {
  return (
    <div
      className={cn(
        'flex items-center gap-1 bg-jacarta-100 w-full text-black rounded-full px-2 py-1 text-sm whitespace-nowrap font-display',
        className,
      )}
    >
      <span className="shrink-0">{icon}</span>
      <div
        className={cn('truncate flex-1 text-start', textClassName)}
        title={value + ''}
      >
        {value}
      </div>
      {showEndIcon && <span className="shrink-0">{icon}</span>}
    </div>
  );
};

export default TournamentPills;
