import { modalStyle, primaryButton } from '@/theme/nextUI';
import {
  Button,
  cn,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  useDisclosure,
} from '@nextui-org/react';
import { useTranslation } from 'react-i18next';

const Rules = ({ gameId }: { gameId: number }) => {
  const { t } = useTranslation();

  const {
    isOpen: isGameModeOpen,
    onClose: onGameModeClose,
    onOpenChange: onGameModeOpenChange,
    onOpen: onGameModeOpen,
  } = useDisclosure();

  const {
    isOpen: isRestrictedHerosOpen,
    onClose: onRestrictedHerosClose,
    onOpenChange: onRestrictedHerosOpenChange,
    onOpen: onRestrictedHerosOpen,
  } = useDisclosure();

  const BULLETS = [
    {
      title: 'Solo Play Advantage',
      description: `Play solo to gain a score bonus`,
      games: [1, 2, 3],
    },
    {
      title: 'Game Duration Advantage',
      description: 'Finish the game faster to gain a bonus on your score',
      games: [1, 3],
    },
    {
      title: 'Restricted Heroes',
      description: 'Play with the approved heroes to get a score bonus',
      games: [3],
    },
    {
      title: '200 Trophies',
      description:
        'Play with heroes that have more than 200 trophies to get a score bonus',
      games: [4],
    },
    {
      title: 'Ranked Mode Only',
      description:
        'Join the challenge in ranked modes for a competitive and thrilling experience',
      games: [5],
    },
    {
      title: 'Pick Your Game',
      description:
        'Compete in League of Legends, Valorant, Fortnite, or any supported game',
      games: [6],
    },
    {
      title: 'Enter Tournaments',
      description: 'Play in Elite or GG Tournaments to earn points',
      games: [6],
    },
    {
      title: 'Score Points',
      description:
        'Points are based on tournament type, rank, player count, and in-game performance',
      games: [6],
    },
  ];

  const RESTRICTED_HEROS = [
    {
      name: 'Zeus',
      image: 'zeus',
    },
    {
      name: 'Clockwork',
      image: 'clockwork',
    },
  ];

  const RESTRICTED_GAME_MODES = [
    // {
    //   name: 'Ranked Zero Build BR',
    //   image: 'zero build',
    // },
    {
      name: 'Ranked Battle Royale',
      image: 'battle royale',
    },
  ];

  return (
    <>
      <div className="flex flex-col gap-y-2">
        {gameId != 6 && (
          <h2 className="text-lg mb-1 text-center">{t('Fairplay')}</h2>
        )}

        {BULLETS.filter(({ games }) => games?.includes(gameId)).map(
          ({ title, description }) => (
            <p key={title} className="ltr:font-body font-normal text-sm">
              <span className="font-semibold">{t(title)}:</span>{' '}
              {t(description)}.
            </p>
          ),
        )}

        {gameId == 0 && (
          <p className="mt-2 text-center">
            {t('Play, perform, and enjoy the competition!')}
          </p>
        )}

        {gameId == 3 && (
          <div className="flex justify-center pt-2">
            <Button
              className={cn(primaryButton)}
              radius="full"
              onPress={onRestrictedHerosOpen}
            >
              {t('Restricted Heroes')}
            </Button>
          </div>
        )}

        {gameId == 5 && (
          <div className="flex justify-center pt-2">
            <Button
              className={cn(primaryButton)}
              radius="full"
              onPress={onGameModeOpen}
            >
              {t('Allowed Game Mode')}
            </Button>
          </div>
        )}
      </div>

      <Modal
        isOpen={isRestrictedHerosOpen}
        onClose={onRestrictedHerosClose}
        onOpenChange={onRestrictedHerosOpenChange}
        classNames={modalStyle}
      >
        <ModalContent>
          <ModalHeader>
            <h5 className="modal-title">{t('Restricted Heros')}</h5>
          </ModalHeader>
          <ModalBody className="pb-5 grid grid-cols-4">
            {RESTRICTED_HEROS.map(({ name, image }, i) => (
              <div key={name} className="flex flex-col gap-y-2 items-center">
                <Image
                  src={`/images/restricted-heros/${image}.jpg`}
                  alt="Hero Icon"
                  width={80}
                  height={80}
                  classNames={{ wrapper: 'max-w-none' }}
                  className="rounded-none"
                />
                {name}
              </div>
            ))}
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isGameModeOpen}
        onClose={onGameModeClose}
        onOpenChange={onGameModeOpenChange}
        classNames={modalStyle}
      >
        <ModalContent>
          <ModalHeader>
            <h5 className="modal-title">{t('Fortnite Allowed Game Modes')}</h5>
          </ModalHeader>
          <ModalBody className="pb-5 grid grid-cols-2">
            {RESTRICTED_GAME_MODES.map(({ name, image }, i) => (
              <div key={name} className="flex flex-col gap-y-2 items-center">
                <Image
                  src={`/images/fortnite-modes/${image}.jpeg`}
                  alt="Game Mode Icon"
                  width={160}
                  height={80}
                  classNames={{ wrapper: 'max-w-none' }}
                  className="rounded-md"
                />
                {name}
              </div>
            ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Rules;
