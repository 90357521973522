import Image from 'next/image';

const fullLoading = () => {
  return (
    <div
      className="h-screen w-screen grid place-content-center"
      style={{
        background:
          'linear-gradient(90deg,rgba(16, 17, 48, 1) 0%,rgba(71, 46, 74, 1) 35%,rgba(32, 31, 80, 1) 100%)',
      }}
    >
      <Image
        src="/images/icon_logo.png"
        width="128"
        height="128"
        alt="logo"
        className="animate-pulse"
      />
    </div>
  );
};

export default fullLoading;
