import { getLetterColor } from '@/lib/helpers';
import { Avatar, cn } from '@nextui-org/react';
import Link from 'next/link';
import { ReactNode } from 'react';
import Flag from 'react-world-flags';

interface UserAvatarI {
  user: {
    username: string;
    profile_pic: string;
    country_code: string;
  };
  belowUsername?: ReactNode;
}

const UserAvatar = ({ user, belowUsername }: UserAvatarI) => {
  return (
    <Link
      className="flex items-center gap-2 w-max"
      href={`/u/${user?.username}`}
    >
      {/* <Avatar
        src={user?.profile_pic ?? '/images/gamelogo/small/gamerg-white.png'}
        style={{
          backgroundColor: getLetterColor(user?.username[0]),
        }}
        radius="lg"
        alt="user image"
        className="rounded-md w-12 h-12 min-w-12 min-h-12 border-2 border-jacarta-100 dark:border-jacarta-600 text-jacarta-900 text-xl "
      /> */}
      <UserImage user={user} hasBorder />

      <div className="flex flex-col items-start justify-center">
        <div className="flex items-center gap-2">
          <span className="font-display text-medium">{user?.username}</span>
          <Flag
            code={user?.country_code}
            style={{
              width: '24px',
              height: '24px',
            }}
          />
        </div>
        {belowUsername}
      </div>
    </Link>
  );
};

export default UserAvatar;

export const UserImage = ({
  user,
  raduis,
  showShadow,
  hasBorder,
  className,
}: {
  user: {
    username: string;
    profile_pic: string;
    country_code: string;
  };
  raduis?: 'sm' | 'md' | 'lg' | 'none' | 'full';
  showShadow?: boolean;
  hasBorder?: boolean;
  className?: string;
}) => {
  return (
    <Avatar
      src={user?.profile_pic ?? '/images/gamelogo/small/gamerg-white.png'}
      style={{
        backgroundColor: user?.profile_pic
          ? ''
          : getLetterColor(user?.username[0]),
      }}
      radius={raduis ?? 'sm'}
      alt="user image"
      className={cn(
        'w-12 h-12',
        !user?.profile_pic && 'p-1.5',
        showShadow && 'shadow-base',
        hasBorder && 'border-2 border-jacarta-100 dark:border-jacarta-600',
        className,
      )}
    />
  );
};
