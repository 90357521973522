import { cn, Image } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';

const Points = ({
  gameId,
  isCompact = false,
}: {
  gameId: number;
  isCompact?: boolean;
}) => {
  const { t } = useTranslation();
  const KDA = [1, 2, 3];

  const SCORING_LIST = [
    {
      label: 'Kills',
      score: 5,
      image: 'kills',
      games: [...KDA],
      invert: true,
    },
    {
      label: 'Assists',
      score: 4,
      image: 'assists',
      games: [...KDA],
      invert: true,
    },
    {
      label: 'Deaths',
      score: -4,
      image: 'deaths',
      games: [...KDA],
      invert: true,
    },
    {
      label: 'Minions',
      score: 0.05,
      image: 'minions',
      games: [1],
      invert: true,
    },
    {
      label: 'ACS',
      score: 0.05,
      image: 'acs',
      games: [2],
      invert: true,
    },
    {
      label: 'LH',
      score: 0.05,
      image: 'minions',
      games: [3],
      invert: true,
    },
    { label: 'Win', score: 50, image: 'win', games: [...KDA], invert: true },
    {
      label: 'Kills',
      score: 10,
      image: 'kills',
      games: [5],
      invert: true,
    },
    {
      label: 'Performance score',
      score: 0.2,
      image: 'performance',
      games: [5],
      invert: true,
    },
    {
      label: 'Top 1',
      score: 100,
      image: 'top-1',
      games: [5],
      invert: true,
    },
    {
      label: 'Hero Trophies',
      score: 1,
      image: 'hero',
      games: [4],
      invert: false,
    },
    {
      label: 'Trophies',
      score: 100,
      image: 'trophies',
      games: [4],
      invert: false,
    },
  ];

  const LEAGUE_SCORING = [
    { rank: '1st', elite: 550, gg: 470 },
    { rank: '2nd', elite: 520, gg: 440 },
    { rank: '3rd', elite: 490, gg: 410 },
    { rank: '4th', elite: 460, gg: 380 },
    { rank: '5th', elite: 430, gg: 350 },
    { rank: '6th', elite: 400, gg: 320 },
    { rank: '7th', elite: 370, gg: 290 },
    { rank: '8th', elite: 340, gg: 260 },
    { rank: '9th', elite: 310, gg: 230 },
    { rank: '10th', elite: 280, gg: 200 },
  ];

  return (
    <div className="flex items-center justify-evenly gap-3 flex-wrap w-full">
      {gameId == 6 && (
        <div className="flex flex-col items-center font-display w-full">
          <div className="grid grid-cols-[3fr,1fr,1fr] items-center justify-between w-full mb-1.5">
            <span>{t('Rank in tournament')}</span>
            <span className="text-center">{t('Elite')}</span>
            <span className="text-center">{t('GG')}</span>
          </div>
          {LEAGUE_SCORING.map(({ rank, elite, gg }) => (
            <div
              key={rank}
              className="grid grid-cols-[3fr,1fr,1fr] items-center justify-between w-full"
            >
              <span>{t(rank)}</span>
              <span className="text-center">{elite}</span>
              <span className="text-center">{gg}</span>
            </div>
          ))}

          <p className="mt-2">
            {t(
              'Points vary based on player count and your performance relative to others in the tournament',
            )}
            .
          </p>
          <p className="mt-2 text-sm text-jacarta-300">
            {t(
              'If two players have the same score, the tie-breaker will be determined by the player who joined first',
            )}
            .
          </p>
        </div>
      )}

      {gameId != 6 &&
        SCORING_LIST.filter(({ games }) => games.includes(gameId)).map(
          ({ label, score, image, invert }) => (
            <div
              key={label}
              className="flex flex-col gap-y-1 items-center font-display"
            >
              <Image
                src={`/images/scoring/${image}.png`}
                alt="Scoring Icon"
                width={isCompact ? 30 : 34}
                height={isCompact ? 30 : 34}
                className={cn(
                  'rounded-none dark:invert selection:invert',
                  isCompact && 'invert',
                )}
              />
              <h2 className="!font-display text-center text-sm">{label}</h2>
              <span
                className={cn(
                  'font-black text-center',
                  isCompact ? 'text-lg' : 'text-xl',
                )}
                dir="ltr"
              >
                {score}
              </span>
            </div>
          ),
        )}
    </div>
  );
};

export default Points;
